import React, {
  Children,
  MouseEvent,
  ReactElement,
  cloneElement,
  isValidElement,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Tooltip } from '../Tooltip';

type EllipsisTooltipProps = {
  onMouseOver: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMouseLeave: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  'data-for': string;
  'data-tip': string;
};

type TooltipState =
  | {
      show: true;
      element: Element;
    }
  | { show: false; element: null };

export const EllipsisToolTip: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [tooltipState, setTooltipState] = useState<TooltipState>({
    show: false,
    element: null,
  });

  useEffect(() => {
    if (tooltipState.element) {
      Tooltip.show(tooltipState.element);
    } else {
      Tooltip.hide();
    }
  }, [tooltipState]);

  const mouseEnterHandler = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (e.currentTarget.offsetWidth !== e.currentTarget.scrollWidth) {
        setTooltipState({ show: true, element: e.currentTarget });
      } else if (e.currentTarget.offsetWidth === e.currentTarget.scrollWidth) {
        setTooltipState({ show: false, element: null });
      }
    },
    [setTooltipState],
  );

  const mouseLeaveHandler = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setTooltipState({ show: false, element: null });
    },
    [setTooltipState],
  );

  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child as ReactElement<EllipsisTooltipProps>, {
        onMouseOver: mouseEnterHandler,
        onMouseLeave: mouseLeaveHandler,
        'data-for': child.props['data-for'] || 'hiboo-tooltip',
        'data-tip': tooltipState.show ? child.props['data-tip'] : '',
      });
    }
    return child;
  });

  return <>{childrenWithProps}</>;
};
