export enum FLAGS {
  ANALYTICS_IDLE_RATE_REPORT = 'analytics-idle-rate-v2',
  ANALYTICS_HOURLY_CONSUMPTION_REPORT = 'analytics-hourly-consumption-v2',
  ANALYTICS_CARBON_EMISSIONS_REPORT = 'analytics-carbon-emissions-v-2',
  ANALYTICS_USAGE_HOURS_REPORT = 'analytics-usage-hours-v-2',

  ASSET_DETAILS_CONDENSED = 'asset-details-condensed',

  ACTIVITY_ALERTS = 'activity-alerts',
  APP_SYNC_ONLY = 'app-sync-only',
  ASSET_TRIPS = 'asset-trips',
  CUSTOM_MAP_TILES = 'custom-map-tiles',
  SATELLITE_STADIA_MAPS = 'satellite-stadia-maps',
  DAILY_DATA = 'daily-data',
  DATA_HISTORY_SPAN = 'data-history-span',
  ENERGY_TYPE = 'energy-type',
  DESTINATIONS = 'destinations',
  FAULTS_REPORT = 'faults-report',
  BULLET_PUBLIC_PAGE = 'bullet-public-page',
  BULLET_PUBLIC_PAGE_ASSIGN = 'bullet-public-link-assign',
  FILE_ANALYTICS_EXPORT = 'file-analytics-export',
  FILE_INVENTORY_EXPORT = 'file-inventory-export',
  INVENTORY_ENGINE_CONDITION = 'inventory-engine-condition',
  NO_REPORT = 'no-report',
  REPORT_A_PROBLEM = 'report-a-problem',
  TYRE_V2 = 'tyre-v2',
  USAGE_REPORT = 'usage-report',
  WORKSPACE_CREATE = 'workspace-create',

  TMP_SHARING_FORM_HUBSPOT = 'tmp-sharing-form-hubspot',
  FLEET_SHARING = 'fleet-sharing',
  FLEET_LEGACY_SHARING = 'fleet-legacy-sharing',

  ALERT_MEMBERS = 'alert-members',

  ANALYTICS_DATAKIND_DISPLAY = 'analytics-datakind-display',
  ANALYTICS_DAILY_USAGE_HOURS_EXPORT = 'analytics-daily-usage-hours-export',
  NEW_ENTERPRISE_FIELD = 'new-enterprise-field',
  FLEET_DOCUMENT_EXPIRATION_DATE = 'fleet-document-expire-at',
  FLEET_CUSTOM_HOMEPAGE = 'first-page',
  FAULT_GROUP_BY_ASSET_PAGINATED = 'fault-group-by-asset-paginated',
  ANALYTICS_EXPORT_BI_MODE = 'export-bi-mode',
}
