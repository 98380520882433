import colors from 'constants/colors';
import { CheckIcon } from 'modules/common-ui';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  OptionProps,
  Options,
  components as ReactSelectComponents,
  SingleValue,
} from 'react-select';
import { default as ReactCreatableSelect } from 'react-select/creatable';
import { SelectCreateLabel } from './SelectCreateLabel';
import {
  buildCustomStyle,
  buildCustomTheme,
  IconContainer,
  OptionContainer,
} from './style.css';

type OptionType = {
  value: string;
  label: string;
};

type CreatableSelectProps<T> = {
  id: string;
  inputValue: string;
  options: Options<T>;
  value: SingleValue<T>;
  loading: boolean;
  onChange: (option: SingleValue<T>) => void;
  onBlur: (event: React.FocusEvent<HTMLElement>) => void;
  onInputChange: (inputValue: string) => void;
  placeholder: string;
  disabled?: boolean;
  createLabelText: string;
  createLabelIcon?: React.ReactNode;
  isClearable?: boolean;
  components?: any;
  error?: FieldError;
  optionContent?: (props: OptionProps<T>) => React.ReactNode;
  maxMenuHeight?: number;
};

export const CreatableSelect = <T extends OptionType>({
  id,
  inputValue,
  options,
  value,
  loading,
  onBlur,
  onChange,
  onInputChange,
  placeholder,
  disabled = false,
  createLabelText,
  createLabelIcon,
  isClearable = false,
  components = {},
  error = undefined,
  optionContent = undefined,
  maxMenuHeight = 300,
}: CreatableSelectProps<T>) => {
  const { t } = useTranslation('commonUi');

  const Option = (props: OptionProps<T>) => {
    const { data, children } = props;
    const isSelected = data.value === value?.value;
    const customChildren = (
      <OptionContainer>
        {optionContent ? optionContent(props) : children}
        {isSelected && (
          <IconContainer>
            <CheckIcon color={colors.gray850} />
          </IconContainer>
        )}
      </OptionContainer>
    );
    return (
      <ReactSelectComponents.Option {...props} children={customChildren} />
    );
  };

  const customComponents = {
    ...components,
    IndicatorSeparator: null,
    Option,
  };

  return (
    <ReactCreatableSelect
      id={id}
      components={customComponents}
      isClearable={isClearable}
      maxMenuHeight={maxMenuHeight}
      closeMenuOnSelect={true}
      value={value}
      options={options}
      isLoading={loading}
      isMulti={false}
      onChange={onChange}
      onInputChange={onInputChange}
      onBlur={onBlur}
      inputValue={inputValue}
      placeholder={placeholder}
      isDisabled={disabled}
      formatCreateLabel={(inputValue: string) => (
        <SelectCreateLabel
          inputValue={inputValue}
          icon={createLabelIcon}
          text={createLabelText}
        />
      )}
      aria-label={placeholder}
      styles={{ ...buildCustomStyle(!!error) }}
      theme={buildCustomTheme}
      noOptionsMessage={() => t('creatableSelect.noOptionsMessage')}
    />
  );
};
