import {
  NavNext,
  NavPrev,
} from 'modules/common-ui/styles/DatePickers/commonStyle';
import moment from 'moment';
import { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import { CalendarIcon, ChevronLightLeft, ChevronLightRight } from '../Icons';
import { StyledSingleDatePickerWrapper } from './DatePicker.css';

export type DatePickerPropType = {
  id: string;
  onDateChange: (date: moment.Moment | null) => void;
  defaultToday?: boolean;
  defaultValue?: moment.Moment | null;
  small: boolean;
  displayDateFormat?: string;
  numberOfMonths?: number;
  disabled?: boolean;
  showCalendarIcon?: boolean;
  placeholder?: string;
  inputIconPosition?: 'after' | 'before';
};

export const DatePicker = ({
  id,
  onDateChange,
  defaultToday = false,
  defaultValue = null,
  small = true,
  displayDateFormat = 'DD/MM/YYYY',
  numberOfMonths = 1,
  disabled = false,
  showCalendarIcon = true,
  placeholder = 'JJ/MM/AAAA',
  inputIconPosition = 'before',
}: DatePickerPropType) => {
  const [date, setDate] = useState<moment.Moment | null>(
    defaultValue ? defaultValue : defaultToday ? moment() : null,
  );
  const [focused, setFocused] = useState<boolean>(false);

  const handleDateChange = (date: moment.Moment | null) => {
    setDate(date);
    onDateChange(date);
  };

  const handleFocusChange = ({ focused: isFocused }: { focused: boolean }) => {
    setFocused(isFocused);
  };

  return (
    <StyledSingleDatePickerWrapper>
      <SingleDatePicker
        date={date}
        onDateChange={handleDateChange}
        focused={focused}
        onFocusChange={handleFocusChange}
        id={id}
        small={small}
        isOutsideRange={() => false}
        numberOfMonths={numberOfMonths}
        hideKeyboardShortcutsPanel
        daySize={32}
        renderNavPrevButton={(props) =>
          !props.disabled && (
            <NavPrev {...props} top={14}>
              {<ChevronLightLeft />}
            </NavPrev>
          )
        }
        renderNavNextButton={(props) =>
          !props.disabled && (
            <NavNext {...props} top={14}>
              {<ChevronLightRight />}
            </NavNext>
          )
        }
        verticalSpacing={0}
        disabled={disabled}
        customInputIcon={showCalendarIcon ? <CalendarIcon size={18} /> : null}
        displayFormat={displayDateFormat}
        inputIconPosition={inputIconPosition}
        placeholder={placeholder}
        required={false}
        showClearDate={true}
      />
    </StyledSingleDatePickerWrapper>
  );
};
