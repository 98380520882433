import { FLAGS } from 'constants/flags';
import {
  FeatureFlagsActionTypes,
  useFeatureFlags,
  useFeatureFlagsDispatch,
} from 'context/feature-flags/FeatureFlagsContext';
import { apiClient } from 'modules/api-client';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'reducers';

export function useFeature<FlagType = any>(
  featureFlag: FLAGS,
  initialLoading = false,
): [FlagType, boolean] {
  const featureFlagsDispatch = useFeatureFlagsDispatch();
  const featureFlags = useFeatureFlags();
  const defaultFlagValue = featureFlags[featureFlag];
  const [value, setValue] = useState<FlagType>(defaultFlagValue);
  const [loading, setLoading] = useState(initialLoading);

  const userToken: string = useSelector(
    (state: Store) => state.user.token || '',
  );
  useEffect(() => {
    if (userToken?.length) {
      setLoading(true);
      apiClient.flag
        .getFlagCheck(featureFlag, userToken)
        .then((response: any) => {
          const flagValue = response?.value;
          if (flagValue !== defaultFlagValue) {
            featureFlagsDispatch({
              type: FeatureFlagsActionTypes.SET_FEATURE_FLAG,
              payload: {
                [featureFlag]: flagValue,
              },
            });
            setValue(flagValue);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [featureFlag, userToken, defaultFlagValue, featureFlagsDispatch]);

  return [value, loading];
}
