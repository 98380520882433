import { GraphQLWorkspace } from 'modules/types';

export type WorkspaceOption = GraphQLWorkspace & {
  key: string;
  label: string;
  value: number;
};

export function buildOptionsWorkspaces(
  workspaces: GraphQLWorkspace[],
): WorkspaceOption[] {
  const optionsWorkspaces = workspaces.map((ws) => {
    const label = ws.name;
    return {
      ...ws,
      value: ws.id,
      label,
      key: `${ws.id}`,
    };
  });

  return optionsWorkspaces.sort((a, b) => a.label.localeCompare(b.label));
}
