import { FLAGS } from 'constants/flags';
import { useFeature } from 'modules/utils';

const defaultHomepageUrl = '/map';

export function useHomepageUrl(options: {
  organizationId: number | undefined | null;
  isOnboarding?: boolean;
}): [url: string, isLoading: boolean] {
  const { organizationId, isOnboarding } = options;
  const [configuredHomepageUrl, isLoading] = useFeature(
    FLAGS.FLEET_CUSTOM_HOMEPAGE,
    true,
  );

  if (!organizationId) {
    return ['', false];
  }

  if (isOnboarding) {
    return ['/onboarding', false];
  }

  return [configuredHomepageUrl || defaultHomepageUrl, isLoading];
}
