import colors, { primaryColors } from 'constants/colors';
import styled from 'styled-components';

const Nav = `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  position: absolute;
  font-weight: 600;
  border: none;
  background: ${colors.white};
  border-radius: 50%;

  :hover {
    background: ${primaryColors.main};
    color: ${colors.white};
    cursor: pointer;

    svg {
      path {
        stroke: white;
      }
    }
  }

  :active {
    border: none;
    text-decoration: none;
  }

  :focus {
    outline: 1px solid ${primaryColors.main};
  }
`;

export const NavPrev = styled.button<{ top?: number }>`
  ${Nav}
  left: 22px;
  top: ${(props) => props.top || 18}px;
 `;

export const NavNext = styled.button<{ top?: number }>`
  ${Nav}
  right: 22px;
  top: ${(props) => props.top || 18}px;
`;

export const CalendarDay = `
  .CalendarDay {
    border: none;
    height: 12px;
    width: 12px;
    :hover {
      background: ${primaryColors.fade};
    }
  }

  .CalendarDay__today {
    background: ${colors.yellow100};
    border-color: ${colors.gray100};
    border-radius: 50%;
    color: ${colors.gray850};
  }

  .CalendarDay__selected {
    background: ${primaryColors.main};
    border-color: ${colors.gray100};
    color: ${colors.white};
    border-radius: 5px;
    :hover {
      background: ${primaryColors.main};
    }

    &.CalendarDay__today {
      border-radius: 50%;
    }
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span {
    border-color: ${colors.gray100};
    color: ${colors.gray850};
    background: ${primaryColors.fade};
  }
`;
