import colors from 'constants/colors';
import styled from 'styled-components';

export const StyledLink = styled.a`
  border-radius: 3px;
  color: ${colors.gray500};
  text-decoration: none;
  &:hover {
    color: ${colors.gray500};
    text-decoration: none;
    background-color: rgba(45, 47, 48, 0.08);
  }
  &:active {
    color: ${colors.gray500};
    text-decoration: none;
    background-color: ${colors.grayHover};
  }
`;
