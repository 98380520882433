import colors from 'constants/colors';
import { Checkbox } from 'modules/common-ui';
import styled from 'styled-components';
import { SearchAndLoadMultiselectOption } from './types';

const List = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 320px;
  overflow-y: auto;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  min-height: 28px;

  border-radius: 5px;
  transition: background 120ms ease-in 0s;

  :hover {
    background: rgba(45, 47, 48, 0.08);
  }

  :active {
    background: ${colors.grayHover};
  }
`;

const ListRow = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
  align-items: center;
  max-width: 308px;
  width: 308px;
  transition: background 120ms ease-in 0s;
  padding: 8px;
`;

const Label = styled.span`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.gray850};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const SearchAndLoadOptionsList = ({
  selectedOptions,
  listOptions,
  listSize,
  onUpdate,
  setUserHasInteractedAtLeastOnce,
}: {
  selectedOptions: SearchAndLoadMultiselectOption[];
  listOptions: SearchAndLoadMultiselectOption[];
  listSize: number;
  onUpdate: (value: any) => void;
  setUserHasInteractedAtLeastOnce: (value: boolean) => void;
}) => {
  const isOptionSelected = (value: any) => {
    return (
      selectedOptions.findIndex((selected) => selected.value === value) !== -1
    );
  };

  const unselectOption = (value: any) => {
    onUpdate(selectedOptions.filter((selected) => selected.value !== value));
  };

  const selectOption = (value: any) => {
    const option = listOptions.find((_option) => _option.value === value);
    setUserHasInteractedAtLeastOnce(true);
    onUpdate([...selectedOptions, option]);
  };

  const toggleCheckbox = (value: any) => {
    if (isOptionSelected(value)) {
      unselectOption(value);
      return;
    }
    selectOption(value);
  };

  return (
    <List>
      <>
        {listOptions.slice(0, listSize).map((o) => (
          <Row
            key={`row-${o.key}`}
            onClick={() => {
              toggleCheckbox(o.value);
            }}
          >
            <ListRow>
              <Checkbox
                checked={isOptionSelected(o.value)}
                onChange={() => toggleCheckbox(o.value)}
              />
              <Label>{o.label}</Label>
            </ListRow>
          </Row>
        ))}
      </>
    </List>
  );
};
