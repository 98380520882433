import colors from 'constants/colors';
import styled from 'styled-components';

type IconWrapperProps = {
  active: boolean;
};

export const IconWrapper = styled.div<IconWrapperProps>`
  color: ${(props) => (props.active ? colors.gray500 : colors.gray500)};
  cursor: pointer;
  height: 24px;
  padding: 4px;
  position: absolute;
  right: 4px;
  top: 4px;
  transition: color 120ms ease-in 0s;

  &:hover {
    color: ${(props) => (props.active ? colors.gray500 : colors.gray850)};
  }
`;
