export type DownArrowProps = {
  size?: number;
};

export const DownArrow = ({ size = 16 }: DownArrowProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 16 16`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9785 6.4447L8.97852 10.4447L4.97853 6.4447"
      stroke="#2D2F30"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
