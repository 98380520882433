import moment, { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import {
  FocusedInputShape,
  DateRangePicker as RDDateRangePicker,
} from 'react-dates';
import { useTranslation } from 'react-i18next';

import {
  NavNext,
  NavPrev,
} from 'modules/common-ui/styles/DatePickers/commonStyle';
import { Button } from '../Button';
import {
  ArrowRightIcon,
  CalendarIcon,
  ChevronLightLeft,
  ChevronLightRight,
} from '../Icons';
import {
  DateRangePeriodType,
  PeriodKeys,
  PeriodOptions,
} from './DateRangePeriods';
import {
  CalendarInfoContainer,
  CalendarInfoRow,
  CalendarInfoRows,
  CalendarInfoTitle,
  DatePickerFooter,
  DatePickerFooterButtonGroup,
  DatePickerFooterInfo,
  StyledWrapper,
  Tip,
} from './DateRangePicker.css';

export type DateRangePickerProps = {
  autoFocus?: boolean;
  initialPeriod?: DateRangePeriodType;
  periodOptions?: PeriodKeys[];
  onDatesSelect: (p: DateRangePeriodType) => void;
  anchorDirection?: 'right' | 'left';
  withMarginTop?: boolean;
  largeInput?: boolean;
  minDate?: Moment;
  maxDate?: Moment;
  optionalEndDate?: boolean;
  useRawPeriodLabelTip?: boolean;
  hidePeriodLabelTip?: boolean;
  isActive: boolean;
  disabled?: boolean;
  additionalInfo?: React.ReactNode;
  forwardRef?: React.RefObject<HTMLDivElement>;
  darkBackground?: boolean;
};

// eslint-disable-next-line max-statements
export const DateRangePicker = ({
  onDatesSelect,
  autoFocus = false,
  initialPeriod = PeriodOptions.last7d,
  periodOptions = [
    'last7d',
    'last15d',
    'lastWeek',
    'lastMonth',
    'startOfMonth',
    'startOfWeek',
    'startOfYear',
    'currentWholeYear',
  ],
  anchorDirection = 'right',
  withMarginTop = false,
  largeInput = false,
  minDate,
  maxDate,
  optionalEndDate = false,
  useRawPeriodLabelTip = false,
  hidePeriodLabelTip = false,
  isActive,
  disabled = false,
  additionalInfo = null,
  forwardRef,
  darkBackground = false,
}: DateRangePickerProps) => {
  const [t] = useTranslation('commonUi');

  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    autoFocus ? 'startDate' : null,
  );

  // update start and end date when initial period changes
  useEffect(() => {
    if (initialPeriod?.since && initialPeriod?.until) {
      setStartDate(initialPeriod.since);
      setEndDate(initialPeriod.until);
      setSelectedPeriod(initialPeriod);
    }
  }, [initialPeriod]);

  const [startDate, setStartDate] = useState<Moment | null>(
    initialPeriod?.since,
  );
  const [endDate, setEndDate] = useState<Moment | null>(initialPeriod?.until);

  const [selectedPeriod, setSelectedPeriod] =
    useState<DateRangePeriodType | null>(initialPeriod);

  const onDatesChange = useCallback(
    ({
      startDate,
      endDate,
    }: {
      startDate: Moment | null;
      endDate: Moment | null;
    }) => {
      // toggle focus when dates change, to allow user to change startDate
      setFocusedInput((focusedInput) => {
        return focusedInput === 'endDate' && !endDate ? 'endDate' : 'startDate';
      });

      setSelectedPeriod(PeriodOptions.custom);
      setStartDate(startDate);
      setEndDate(endDate);
    },
    [],
  );

  const onFocusChange = (pfocusedInput: FocusedInputShape | null) => {
    setFocusedInput(pfocusedInput);
  };

  const openDatePicker = () => {
    if (focusedInput === null) {
      setFocusedInput('startDate');
    }
  };

  const onValidate = useCallback(() => {
    if (startDate && (endDate || optionalEndDate)) {
      if (selectedPeriod) {
        onDatesSelect({
          label: selectedPeriod.label,
          value: selectedPeriod.value,
          since: startDate,
          until: endDate,
        });
      } else {
        onDatesSelect({
          label: PeriodOptions.custom.label,
          value: PeriodOptions.custom.value,
          since: startDate,
          until: endDate,
        });
      }
    }
    setFocusedInput(null);
  }, [onDatesSelect, selectedPeriod, startDate, endDate, optionalEndDate]);

  const onClose = useCallback(() => {
    setSelectedPeriod(initialPeriod);
    setFocusedInput(null);
  }, [initialPeriod]);

  const isEmpty = !periodOptions || periodOptions.length === 0;
  const renderCalendarInfo = useCallback(() => {
    return (
      <CalendarInfoContainer isEmpty={isEmpty}>
        <CalendarInfoTitle>{t('dateFilter.options')}</CalendarInfoTitle>
        <CalendarInfoRows>
          {periodOptions.map((key: PeriodKeys) => {
            return (
              <CalendarInfoRow
                selected={
                  !!(
                    selectedPeriod &&
                    selectedPeriod.value === PeriodOptions[key].value
                  )
                }
                key={`info-row-${PeriodOptions[key].value}`}
                onClick={() => {
                  setSelectedPeriod(PeriodOptions[key]);
                }}
              >
                {t(PeriodOptions[key].label)}
              </CalendarInfoRow>
            );
          })}
        </CalendarInfoRows>
        <DatePickerFooter>
          <DatePickerFooterInfo>{additionalInfo}</DatePickerFooterInfo>
          <DatePickerFooterButtonGroup>
            <Button onClick={onClose}>{t('dateFilter.actions.cancel')}</Button>
            <Button
              disabled={!startDate || (!endDate && !optionalEndDate)}
              onClick={onValidate}
              variant={'primary'}
            >
              {t('dateFilter.actions.validate')}
            </Button>
          </DatePickerFooterButtonGroup>
        </DatePickerFooter>
      </CalendarInfoContainer>
    );
  }, [
    isEmpty,
    t,
    periodOptions,
    onClose,
    startDate,
    endDate,
    optionalEndDate,
    onValidate,
    selectedPeriod,
    additionalInfo,
  ]);

  useEffect(() => {
    if (selectedPeriod && (selectedPeriod.since || selectedPeriod.until)) {
      setStartDate(selectedPeriod.since);
      setEndDate(selectedPeriod.until);
    }
  }, [selectedPeriod, setStartDate, setEndDate, setFocusedInput]);

  const checkDateIsOutsideRange = useCallback(
    (day: any): boolean => {
      const isBeforeMinDate = !!minDate && moment(day).isBefore(minDate);
      const isAfterMaxDate =
        !!maxDate && moment(day).startOf('day').isAfter(maxDate);
      return isBeforeMinDate || isAfterMaxDate;
    },
    [maxDate, minDate],
  );

  let periodTip = '';
  if (selectedPeriod && selectedPeriod.value) {
    if (selectedPeriod.value === PeriodOptions.custom.value) {
      periodTip = t(PeriodOptions.custom.label);
    } else {
      periodTip = useRawPeriodLabelTip
        ? selectedPeriod.label
        : t(selectedPeriod.label);
    }
  }

  return (
    <StyledWrapper
      ref={forwardRef}
      onClick={() => {
        openDatePicker();
      }}
      withMarginTop={withMarginTop}
      focused={focusedInput !== null}
      active={isActive}
      largeInput={largeInput}
      darkBackground={darkBackground}
    >
      {selectedPeriod && selectedPeriod.value && !hidePeriodLabelTip && (
        <Tip>{periodTip}</Tip>
      )}
      <CalendarIcon />
      <RDDateRangePicker
        anchorDirection={anchorDirection}
        displayFormat={'DD/MM/YY'}
        startDate={startDate}
        startDatePlaceholderText={t('dateFilter.startDatePlaceholder')}
        startDateId="date_range_picker_start_date"
        endDate={endDate}
        endDatePlaceholderText={
          optionalEndDate
            ? t('dateFilter.optionalEndDatePlaceholder')
            : t('dateFilter.endDatePlaceholder')
        }
        endDateId="date_range_picker_end_date"
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        hideKeyboardShortcutsPanel
        renderCalendarInfo={renderCalendarInfo}
        daySize={32}
        calendarInfoPosition="before"
        initialVisibleMonth={() => moment().subtract(1, 'months')}
        minimumNights={0}
        isOutsideRange={checkDateIsOutsideRange}
        minDate={minDate}
        maxDate={maxDate}
        onClose={onClose}
        keepOpenOnDateSelect
        renderNavPrevButton={(props) =>
          !props.disabled && (
            <NavPrev {...props}>{<ChevronLightLeft />}</NavPrev>
          )
        }
        renderNavNextButton={(props) =>
          !props.disabled && (
            <NavNext {...props}>{<ChevronLightRight />}</NavNext>
          )
        }
        small={false}
        customArrowIcon={<ArrowRightIcon size={14} />}
        verticalSpacing={0}
        disabled={disabled}
      ></RDDateRangePicker>
    </StyledWrapper>
  );
};
