import { GraphQLLatestPosition } from 'modules/assets/overview/types';
import { CopyPasteIcon } from 'modules/common-ui';
import { usePositionedElement } from 'modules/common-ui/hooks/usePositionedElement';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { toast } from 'react-toastify';
import { CopyOptionsWrapper, IconWrapper, OptionContainer } from './index.css';

type CopyLocationProps = {
  displayCopyOptions: boolean;
  setDisplayCopyOptions: (displayCopyOptions: boolean) => void;
  latestPosition: GraphQLLatestPosition;
};

export const CopyLocation = ({
  displayCopyOptions,
  setDisplayCopyOptions,
  latestPosition,
}: CopyLocationProps) => {
  const { t } = useTranslation('assets');

  const copyOptionsRef = useRef<HTMLDivElement | null>(null);
  const selectRef = useRef<HTMLDivElement | null>(null);

  usePositionedElement(
    displayCopyOptions,
    selectRef,
    copyOptionsRef,
    'right',
    undefined,
  );

  if (!latestPosition) {
    return null;
  }

  const onClickOption = (value: string) => {
    navigator.clipboard.writeText(value);
    setDisplayCopyOptions(false);
    toast.success(t('overview.details.profile.copyToClipboard.success'));
  };

  return (
    <div>
      <IconWrapper ref={selectRef}>
        <CopyPasteIcon />
      </IconWrapper>
      {displayCopyOptions && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setDisplayCopyOptions(false);
          }}
        >
          <CopyOptionsWrapper ref={copyOptionsRef}>
            <OptionContainer
              isUnavailable={!latestPosition.postalAddress}
              onClick={(e) => {
                e.stopPropagation();
                onClickOption(latestPosition.postalAddress!);
              }}
            >
              {t('overview.details.profile.copyToClipboard.address')}
            </OptionContainer>
            <OptionContainer
              onClick={(e) => {
                e.stopPropagation();
                onClickOption(
                  `${latestPosition.latitude}, ${latestPosition.longitude}`,
                );
              }}
            >
              {t('overview.details.profile.copyToClipboard.coordinates')}
            </OptionContainer>
          </CopyOptionsWrapper>
        </OutsideClickHandler>
      )}
    </div>
  );
};
