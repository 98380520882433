import { AddressPin, Button } from 'modules/common-ui';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CellSubtitle, CellTitle, Container, StyledLink } from './index.css';

export type LatestPositionCellProps = {
  id: number | string;
  title: string;
  subtitle?: string;
  zoneIds?: string[];
  dataFor?: any;
  dataTip?: any;
  showLink?: boolean;
  showLinkOnRowHover?: boolean;
  linkTooltip?: string;
  withPaddingLeft?: boolean;
};

export const LatestPositionCell = (props: LatestPositionCellProps) => {
  const { t } = useTranslation('translation');
  const {
    id,
    title,
    subtitle = '',
    zoneIds = [],
    dataFor = '',
    dataTip = '',
    showLink = true,
    showLinkOnRowHover = true,
    linkTooltip = '',
    withPaddingLeft = true,
  } = props;

  const zoneId = zoneIds.length ? zoneIds[0] : null;

  return (
    <Container
      key={`position-cell-${id}`}
      data-for={dataFor}
      data-tip={dataTip}
    >
      <CellTitle withPaddingLeft={withPaddingLeft}>
        {zoneId && <AddressPin />}
        <>{title}</>
      </CellTitle>
      {subtitle && (
        <CellSubtitle withPaddingLeft={withPaddingLeft}>
          {subtitle}
        </CellSubtitle>
      )}
      {showLink && zoneId ? (
        <StyledLink
          to={`/zones/${zoneId}`}
          className={showLinkOnRowHover ? 'visible-on-row-hover' : ''}
        >
          <Button tooltip={linkTooltip} size="xs" variant="outline-neutral">
            {t('common.zone')}
          </Button>
        </StyledLink>
      ) : (
        <StyledLink
          to="/inventory"
          className={showLinkOnRowHover ? 'visible-on-row-hover' : ''}
        >
          <Button
            size="xs"
            variant="outline-neutral"
            onClick={() => {
              navigator.clipboard.writeText(title);
              toast.success(t('common.copied'));
            }}
          >
            {t('common.copy')}
          </Button>
        </StyledLink>
      )}
    </Container>
  );
};
