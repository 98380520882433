import moment from 'moment';
import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import { DateFilterFreemium, DateRangePicker } from 'modules/common-ui';

import { DateRangePickerMobile } from '../DateRangePicker';
import {
  DateRangePeriodType,
  DateRangePeriodType2,
  PeriodKeys,
} from '../DateRangePicker/DateRangePeriods';

type DateFilter2Props = {
  anchorDirection?: 'right' | 'left';
  filterPeriodOptions?: PeriodKeys[];
  freemium?: boolean;
  onDateFilter: (period: DateRangePeriodType) => void;
  period?: DateRangePeriodType | DateRangePeriodType2;
  withMarginTop?: boolean;
  largeInput?: boolean;
  maxDate?: moment.Moment;
  minDate?: moment.Moment;
  optionalEndDate?: boolean;
  useRawPeriodLabelTip?: boolean;
  hidePeriodLabelTip?: boolean;
  isActive?: boolean;
  disabled?: boolean;
  additionalInfo?: React.ReactNode;
  datePickerRef?: React.RefObject<HTMLDivElement>;
  darkBackground?: boolean;
};

export const DateFilter2 = ({
  period,
  onDateFilter,
  anchorDirection,
  filterPeriodOptions,
  maxDate,
  minDate,
  optionalEndDate = false,
  isActive = false,
  freemium = false,
  withMarginTop = false,
  largeInput = false,
  useRawPeriodLabelTip = false,
  hidePeriodLabelTip = false,
  disabled = false,
  additionalInfo = null,
  datePickerRef,
  darkBackground = false,
}: DateFilter2Props) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_DESKTOP_START });

  const initialPeriod = useMemo<DateRangePeriodType | undefined>(() => {
    if (period) {
      return {
        label: period.label,
        value: period.value,
        since: period.since ? moment(period.since) : null,
        until: period.until ? moment(period.until) : null,
      };
    }
  }, [period]);

  if (freemium) {
    return <DateFilterFreemium />;
  }

  if (isMobile) {
    return (
      <DateRangePickerMobile
        initialPeriod={initialPeriod}
        onDatesSelect={onDateFilter}
        periodOptions={filterPeriodOptions}
        withMarginTop={withMarginTop}
        maxDate={maxDate}
        minDate={minDate}
        optionalEndDate={false}
        useRawPeriodLabelTip={useRawPeriodLabelTip}
        hidePeriodLabelTip={hidePeriodLabelTip}
        forwardRef={datePickerRef}
        darkBackground={darkBackground}
      />
    );
  }

  return (
    <DateRangePicker
      onDatesSelect={onDateFilter}
      initialPeriod={initialPeriod}
      anchorDirection={anchorDirection}
      periodOptions={filterPeriodOptions}
      withMarginTop={withMarginTop}
      largeInput={largeInput}
      maxDate={maxDate}
      minDate={minDate}
      optionalEndDate={optionalEndDate}
      useRawPeriodLabelTip={useRawPeriodLabelTip}
      hidePeriodLabelTip={hidePeriodLabelTip}
      isActive={isActive}
      disabled={disabled}
      additionalInfo={additionalInfo}
      forwardRef={datePickerRef}
      darkBackground={darkBackground}
    />
  );
};
