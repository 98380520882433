import styled from 'styled-components';

import colors, { primaryColors } from 'constants/colors';
import { GenericStyledInlineLinkCSS } from 'modules/common-ui/components/InlineLink/index.css';

export const Container = styled.div<{ withMarginTop?: boolean }>`
  position: relative;
  width: fit-content;
  ${(props) => props.withMarginTop && `margin-top: 16px;`}
`;

const Nav = `
  align-items: center;
  background: ${colors.white};
  border: none;
  border-radius: 50%;
  display: flex;
  font-weight: 600;
  height: 36px;
  justify-content: center;
  position: absolute;
  top: 20px;
  width: 36px;

  :hover {
    cursor: pointer;
  }

  :active {
    background: ${colors.grayHover};
    border: none;
    text-decoration: none;
  }

  :focus {
    outline: none;
  }
`;

export const NavPrev = styled.button`
  left: 4px;
  ${Nav}
`;

export const NavNext = styled.button`
  right: 4px;
  ${Nav}
`;

export const Tip = styled.span`
  color: ${colors.gray500};
  font-size: 11px;
  left: 2px;
  line-height: 13px;
  position: absolute;
  top: -16px;
`;

export const StyledWrapper = styled.div`
  position: relative;

  .DayPickerNavigation_button {
    border: none;
  }

  .DayPicker_weekHeaders {
    .DayPicker_weekHeader {
      color: ${colors.gray500};
    }
  }

  .CalendarDay {
    border: none;
  }

  .CalendarDay__today {
    background: ${colors.yellow100};
    border-color: ${colors.gray100};
    border-radius: 50%;
    color: ${colors.gray850};
  }

  .CalendarDay__selected {
    background: ${primaryColors.main};
    border-color: ${colors.gray100};
    border-radius: 5px;
    color: ${colors.white};

    &.CalendarDay__today {
      border-radius: 50%;
    }
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span {
    background: ${primaryColors.fade};
    border-color: ${colors.gray100};
    color: ${colors.gray850};
  }
`;

export const DatePickerInput = styled.div<{ darkBackground?: boolean }>`
  align-items: center;
  border-radius: 5px;
  color: ${colors.gray850};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 32px;
  justify-content: space-between;
  line-height: 24px;
  padding: 6px 8px;
  transition: background 120ms ease-in 0s;
  width: fit-content;
  z-index: 2;
  gap: 4px;

  ${(props) => (props.darkBackground ? `background: ${colors.lightGrey};` : `background: ${colors.white};`)}
  ${(props) => (props.darkBackground ? `border: 0.5px solid ${colors.neutral300};` : `border: 1px solid ${colors.gray300}`)}
`;

export const DateLabel = styled.div`
  align-items: center;
  border-radius: 5px;
  display: flex;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  uheight: 26px;
  justify-content: center;
  margin-left: 2px;
  margin-right: 2px;
  width: fit-content;
`;

export const PeriodOption = styled.div<{ selected?: boolean }>`
  align-items: center;
  color: ${colors.gray850};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  min-height: 28px;
  padding: 8px 16px;
  transition: background 120ms ease-in 0s;
  white-space: normal;

  :hover {
    background: rgba(45, 47, 48, 0.08);
  }
  :active {
    background: ${colors.grayHover};
  }

  ${(props) => (props.selected ? 'background: rgba(45,47,48,0.08);' : '')}
  ${(props) => (props.selected ? 'font-weight: 500;' : '')}
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CalendarActions = styled.div`
  align-items: center;
  display: flex;
  padding: 0 16px;

  span {
    color: ${colors.gray850};
    font-size: 14px;
    line-height: 18px;
  }
`;

export const Action = styled.div`
  ${GenericStyledInlineLinkCSS}
  color: ${colors.gray850};
  margin-left: -8px;
`;
