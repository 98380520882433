import colors from 'constants/colors';
import styled from 'styled-components';

export const CopyOptionsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray200};
  border-radius: 5px;
  padding: 8px 0 8px 0;
  width: 184px;
`;

export const OptionContainer = styled.div<{ isUnavailable?: boolean }>`
  padding: 4px 8px 4px 8px;
  height: 32px;
  cursor: pointer;

  :hover {
    background-color: ${colors.gray100};
  }

  ${(props) =>
    props.isUnavailable &&
    `
      color: ${colors.gray500};
      cursor: not-allowed;
    `}
`;

export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
`;
