import colors from 'constants/colors';
import styled from 'styled-components';

export const Container = styled.div`
  border-top: 1px solid ${colors.gray200};
  display: flex;
  flex-direction: column;
  max-height: 360px;
  height: auto;
  overflow: auto;
  transition: transform 0.9s;
  transform: scaleY(1);
`;

export const Header = styled.div`
  padding: 0 16px;
  display: flex;
  height: 32px;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 11px;
  line-height: 13px;
  color: ${colors.gray500};
  text-transform: uppercase;
`;

export const ClearButton = styled.div`
  align-items: center;
  font-size: 11px;
  line-height: 13px;
  border-radius: 3px;
  padding: 4px;
  cursor: pointer;
  color: ${colors.gray500};
  transition: background 120ms ease-in 0s;

  :hover,
  :focus {
    background: rgba(45, 47, 48, 0.08);
    text-decoration: none;
  }

  :active {
    background: ${colors.grayHover};
  }
`;
