import colors from 'constants/colors';
import { CardSection } from 'modules/common-ui';
import styled from 'styled-components';

export const CondensedCardSection = styled(CardSection)``;

export const CardRow = styled.div`
  display: flex;
  font-size: 14px;
  color: #2d2f30;

  & + & {
    margin-top: 16px;
  }

  ${CondensedCardSection} & {
    font-size: 12px;
    color: ${colors.gray850};
    line-height: 16px;
  }
`;

export const RowValue = styled.div`
  flex: 1;
  margin-left: 4px;
  display: flex;
  justify-content: flex-start;
  color: #373530;

  ${CondensedCardSection} & {
    color: ${colors.gray850};
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const RowName = styled.div`
  flex: 0.5;
  font-size: 14px;
  color: #676767;

  ${CondensedCardSection} & {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${colors.gray500};
  }
`;

export const EventDate = styled.span`
  color: ${colors.gray500};
  font-size: 11px;
`;
